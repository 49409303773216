<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2 flex-grow-1">
              <b-badge variant="primary">
                {{ round.roundNumber }}
              </b-badge> {{ round.roundTitle }} » {{ applicantTitle }}
            </h3>
            <h3 class="mb-0">
              <b-badge
                class="font-weight-normal"
                variant="light-secondary"
              >
                {{ programTitle }}
              </b-badge>
            </h3>
          </b-card-header>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group>
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search"
                type="text"
              />
            </b-form-group>
            <b-form-group
              label="Average Score:"
              label-class="pl-25 ml-1 pb-0"
            >
              <b-form-rating
                id="avg-score"
                v-model="avgScore"
                :readonly="true"
                class="py-0"
                no-border
                precision="2"
                show-value
                show-value-max
                stars="10"
                variant="primary"
              />
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'fullName'"
                class="text-nowrap"
              >
                <b-avatar
                  :src="props.row.avatar"
                  class="mx-1"
                />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Score -->
              <span v-else-if="props.column.field === 'direct_score'">
                <b-badge variant="light-primary">
                  {{ props.row.direct_score }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-button
                    size="sm"
                    :to="{ name: $route.name==='startup-view-evaluation' ? 'startup-application-score' : 'application-score', params: { pid: $route.params.pid, apid: $route.params.apid, aid:$route.params.aid, rid: props.row.partner_id}, query: {readonly: 'true'} }"
                    variant="outline-primary"
                  >
                    View Individual Scoring
                  </b-button>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCardHeader,
  BButton,
  BFormRating,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCardHeader,
    BButton,
    BFormRating,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Mentor',
          field(row) {
            return `${row.programs_partnerstable?.users_customuser?.first_name ?? 'N/A'} ${row.programs_partnerstable?.users_customuser?.last_name ?? ''}`
          },
        },
        {
          label: 'Score',
          field: 'direct_score',
        },
        {
          label: 'Recommendations',
          field: 'recommendations',
        },
        {
          label: 'Verdict',
          field: 'verdict',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      round: {},
    }
  },
  computed: {
    avgScore() {
      return (this.rows.reduce((previousValue, currentValue) => previousValue + currentValue.direct_score / this.rows.length, 0)
      ).toFixed(2)
    },
    subtitle() {
      // Program title & Round Number
      return `${this.programTitle} » Round ${this.round.roundNumber}`
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_applicationscorestable(where: {applicant_id_id: {_eq: ${this.$route.params.aid} }, application_form_id: {_eq: ${this.$route.params.apid}}}) {
            applicant_id_id
            application_form_id
            partner_id
            verdict
            direct_score
            recommendations
            programs_partnerstable {
              users_customuser {
                first_name
                last_name
              }
            }
          }
        }`
      },
      update: data => data.programs_applicationscorestable,
    },
    applicantTitle: {
      query() {
        return gql`{
          users_organizationtable(where: {programs_applicantstables: {id: {_eq: ${this.$route.params.aid} }}}) {
            title
          }
        }`
      },
      update(data) {
        return data.users_organizationtable[0].title
      },
    },
    round: {
      query() {
        return gql`
          {
          programs_applicationformstable_aggregate(where: {program_id: {_eq: ${this.$route.params.pid} }, id: {_lte: ${this.$route.params.apid}}}) {
            aggregate {
              count
            }
            nodes {
              title
            }
          }

        }`
      },
      update(data) {
        return {
          roundNumber: data.programs_applicationformstable_aggregate.aggregate.count,
          roundTitle: data.programs_applicationformstable_aggregate.nodes[data.programs_applicationformstable_aggregate.aggregate.count - 1].title,
        }
      },
    },
    programTitle: {
      query() {
        return gql`
          {
          programs_basicinfo_by_pk(id: ${this.$route.params.pid}) {
            title
          }
        }`
      },
      update(data) {
        return data.programs_basicinfo_by_pk.title
      },
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
